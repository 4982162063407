var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),_c('div',{staticClass:"tab-bar-card pt-3 text-h4 text-uppercase brown--text"},[_c('div',[_vm._v(_vm._s(_vm.auth.Institution.name))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.auth.Institution.town))]),_c('div',{staticClass:"d-flex justify-space-between align-end"},[_c('v-select',{staticClass:"ma-3 custom-select",staticStyle:{"max-width":"150px"},attrs:{"items":_vm.lables,"item-text":"name","item-value":"id","background-color":"transparent","item-color":"brown","dense":"","hide-details":"","solo":"","color":"brown"},on:{"change":function($event){return _vm.filterStudents()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-btn',{staticClass:"text-capitalize",attrs:{"height":"30","text":"","color":"grey darken-2","large":""},on:{"click":() => {
            _vm.$router.push({
              name: 'PageEventSchoolSelect',
            });
          }}},[_vm._v(" "+_vm._s(_vm.$t("string.change"))+" ")])],1)]),(_vm.selected_data.length > 0)?_c('div',{staticClass:"list-panel"},[(_vm.showAll)?_c('div',_vm._l((_vm.selected_data),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
            ? 'selected-list-card'
            : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(i + 1)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : ''},[_vm._v(" "+_vm._s(student.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),(_vm.series.type != 'book')?_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                ? 'selected-score'
                : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")]):_vm._e()],1)])}),0):_c('div',[(_vm.topStudents.length > 0)?_c('div',[_vm._l((_vm.topStudents),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(i + 1)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : ''},[_vm._v(" "+_vm._s(student.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),(_vm.series.type != 'book')?_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")]):_vm._e()],1)])}),_c('div',{staticClass:"d-flex justify-center brown--text text-h3 my-0",staticStyle:{"line-height":"15px"}},[_vm._v(" • • • ")])],2):_vm._e(),(_vm.myMates.length > 0)?_c('div',[_vm._l((_vm.myMates),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(_vm.myMatecountStart + i)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : ''},[_vm._v(" "+_vm._s(student.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),(_vm.series.type != 'book')?_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")]):_vm._e()],1)])}),_c('div',{staticClass:"d-flex justify-center brown--text text-h3 my-0",staticStyle:{"line-height":"15px"}},[_vm._v(" • • • ")])],2):_vm._e()])]):_c('div',{staticClass:"list-panel"},[_c('div',{staticClass:"list-card"},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v("No Students in this class")])])]),(_vm.showAllBtn)?_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{attrs:{"small":"","color":"yellow"},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_vm._v(" "+_vm._s(_vm.showAll ? _vm.$t("string.show_less") : _vm.$t("string.show_all"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }